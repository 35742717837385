import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { useEffect, useState } from "react";
import { TransitionGroup } from "react-transition-group";

import styles from "./styles.module.scss";

import { CardImage, CardNote } from "@/components/CardMedia";
import { TYPE_MEDIA } from "@/global/constants";

export const MessageDisplay = ({
  notes,
  images,
  classNameWrap = "",
  containerRef = null,
}) => {
  const classNameContainer = [classNameWrap, styles.container].join(" ");

  const [combinedCards, setCombinedCards] = useState([]);

  const styleListItem = {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    padding: "4px 0px",
  };

  // const combinedAndSortedItems = [...notes, ...images].sort(
  //   (a, b) =>
  //     new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
  // );

  useEffect(() => {
    setCombinedCards(
      [...notes, ...images].sort(
        (a, b) =>
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      ),
    );
  }, [notes, images]);

  return (
    <div className={classNameContainer} ref={containerRef}>
      <List style={{ width: "100%" }}>
        <TransitionGroup>
          {combinedCards.map((item) => {
            if (item.typeMedia === TYPE_MEDIA.NOTE) {
              return (
                <Collapse key={item.id}>
                  <ListItem style={styleListItem}>
                    <CardNote
                      id={item.id}
                      date={item.createdAt}
                      text={item.text}
                      selected={item.selected}
                      typeMedia={TYPE_MEDIA.NOTE}
                    />
                  </ListItem>
                </Collapse>
              );
            }

            if (item.typeMedia === TYPE_MEDIA.IMAGE) {
              return (
                <Collapse key={item.id}>
                  <ListItem style={styleListItem}>
                    <CardImage
                      id={item.id}
                      date={item.createdAt}
                      text={item.text}
                      urlImage={item.urlImage}
                      selected={item.selected}
                      typeMedia={TYPE_MEDIA.NOTE}
                    />
                  </ListItem>
                </Collapse>
              );
            }
          })}
        </TransitionGroup>
      </List>
    </div>
  );
};
