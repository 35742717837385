import Modal from "@mui/material/Modal";
import { useEffect, useRef, useState } from "react";

import styles from "./styles.module.scss";

import { createImagePublic } from "@/api/image";

export const ModalCreateImage = ({ componentOpen }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [imageSrc, setImageSrc] = useState(null);
  const [image, setImage] = useState(null);

  const inputFileRef = useRef(null);

  const handleCustomAction = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }

    // handleOpen();
  };

  const handleCloseModal = () => {
    // handleClose();
    setImageSrc(null);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageSrc(e.target.result);

        setImage(file);
      };
      reader.readAsDataURL(file);

      event.target.value = "";
    }
  };

  const handleCreateImage = async () => {
    if (imageSrc) {
      const a = await createImagePublic(image, "teste");

      console.log(a);

      handleCloseModal();
    }
  };

  useEffect(() => {
    if (imageSrc) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [imageSrc]);

  return (
    <>
      <input
        type="file"
        accept=".png, .jpg, .jpeg"
        name=""
        id=""
        style={{ display: "none" }}
        ref={inputFileRef}
        onChange={handleFileChange}
      />

      <span className={styles.openModal} onClick={handleCustomAction}>
        {componentOpen}
      </span>

      <Modal open={open} onClose={handleCloseModal} className={styles.page}>
        <div className={styles.container}>
          <h3 className={styles.title}>Imagem</h3>

          <div className={styles.imgArea}>
            <img src={imageSrc} />
          </div>

          <input placeholder="Digite a legenda" />

          <div className={styles.wrapButtons}>
            <button className={"btnSecondary"} onClick={handleCloseModal}>
              Cancelar
            </button>
            <button className={"btnPrimary"} onClick={handleCreateImage}>
              Enviar
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
