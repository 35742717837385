import api from "@/api/api";
import { IS_DEV } from "@/global/constants";

export const createImagePublic = async (image, text) => {
  const formData = new FormData();
  formData.append("text", text);
  formData.append("image", image);

  try {
    const response = await api.post("/free/image", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    const imageDB = response.data.image;

    if (imageDB) {
      return imageDB;
    }
  } catch (err) {
    if (IS_DEV) {
      console.log(err);
    }
  }

  return false;
};

export const getImagesPublic = async () => {
  try {
    const response = await api.get("/free/image");
    const responseData = response.data.images;

    if (Array.isArray(responseData)) {
      return responseData;
    }
  } catch (err) {
    if (IS_DEV) {
      console.log(err);
    }
  }

  return false;
};
